import { styled } from 'styled-components';
import { INDICES } from '../../services/Stocks';
import { PriceTemplates, WSPrice } from '../common/WSPrice';

const StyledIndices = styled.div`
  text-align: center;
  > .indice {
    margin-bottom: 20px;
    > div {
      h5 {
        background-color: lightsteelblue;
        font-size: small;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
      }
      border: 1px solid #ccc;
      border-radius: 5px;
      .price {
        padding-bottom: 5px;
        .mainPrice {
          font-size: 22px;
        }
      }
    }
  }
`;

export const Indices = () => {
  const IndicesDisplay = (props: any) => {
    const { displayName, symbol } = props;

    return (
      <div className="col-6 col-md-4 indice">
        <div>
          <h5>{displayName}</h5>
          <div className="price">
            <WSPrice security_id={symbol} showCurrency={false} template={PriceTemplates.INDICES} />
          </div>
        </div>
      </div>
    );
  };
  return (
    <StyledIndices className="row">
      
      {INDICES.map((indice, index) => {
        return <IndicesDisplay key={index} {...indice} />;
      })}
    </StyledIndices>
  );
};
