import { styled } from 'styled-components';
import Marquee from 'react-fast-marquee';

export const StyledMarquee = styled(Marquee)`
  width: calc(100% + 24px);
    margin: 0px -12px;
    border-bottom: 1px solid #ccc;
  .ticker {
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
    border-right: 1px dotted #d2d2d2;
    padding: 0px 10px;
    .title {
      border-bottom: 1px solid #d2d2d2;
    }
    .change {
      justify-content: flex-start;
    }
    .diff {
      margin-right: 5px;
      &.red {
        color: red;
      }
      &.green {
        color: lawngreen;
      }
    }
  }
`;
