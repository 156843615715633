/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { HeaderWrapper } from './Header.styled';
import { useSelector } from 'react-redux';
import { DataLastUpdated } from '../../../Reducers/DefaultReducer';
import moment from 'moment-timezone';

interface HeaderProps {
  dummy?: string;
}

const Header: FC<HeaderProps> = () => {
  const lastUpdated: number = useSelector(DataLastUpdated);
  return (
    <HeaderWrapper data-testid="Header">
      <nav className="navbar">
        <div className="container">
          <a className="navbar-brand" href="#">
            MDStox
          </a>
          <span className='lastUpdated'>
            Data Last Updated:
            <br /> {moment.unix(lastUpdated).format('DD MM YYYY hh:mm:ss A')}
          </span>
        </div>
      </nav>
    </HeaderWrapper>
  );
};

export default Header;
