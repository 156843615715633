import { useDispatch } from 'react-redux';
import { updatePriceList } from '../../Reducers/DefaultReducer';
import { useEffect } from 'react';
const websocketURL =
  process.env.NODE_ENV === 'production'
    ? 'wss://mdstox.onrender.com/'
    : 'ws://localhost:3000/';
const ws = new WebSocket(websocketURL);
export interface PriceProps {
  id?: string;
  price?: number;
  time?: string;
  changePercent?: number;
  dayVolume?: string;
  change?: number;
  high?: number;
  low?: number;
  mode?: number;
  open?: number;
}

export enum ActionTypeEnum {
  'ADD',
  'REMOVE'
}
export enum ModeTypeEnum {
  'LTP',
  'FULL',
  'QUOTE'
}
export enum ScripTypeEnum {
  'ETF',
  'FUTURE',
  'INDEX',
  'OPTION',
  'EQUITY'
}

export enum ExchangeTypeEnum {
  'BSE',
  'NSE'
}

export interface DepthProps {
  [key: string]: any
}

export interface PriceWatchProps {
  actionType?: ActionTypeEnum; // 'ADD', 'REMOVE'
  modeType?: ModeTypeEnum; // 'LTP', 'FULL', 'QUOTE'
  scripType?: ScripTypeEnum; // 'ETF', 'FUTURE', 'INDEX', 'OPTION', 'EQUITY'
  exchangeType?: ExchangeTypeEnum; // 'BSE', 'NSE'
  scripId?: number;
  price: PriceProps;
  name?: string;
  oi?: number;
  oiChange?: number;
  averageTradedPrice?: string;
  close?: string;
  fifty2WeeksHigh?: string;
  fifty2WeeksLow?: string;
  depthPacket?: DepthProps;
}

export interface PriceListProps {
  [key: string]: PriceWatchProps;
}

export const PriceWatch = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    ws.onopen = function () {
      console.log('Opening a connection... - WSPrice');
      ws.send(JSON.stringify([]));
    };
    ws.onmessage = function (message) {
      const data = JSON.parse(message.data);
      if (Array.isArray(data)) {
        dispatch(updatePriceList(data));
      }
    };
    ws.onclose = function (event) {
      console.log('close', event);
    };
  }, [dispatch]);

  return <div></div>;
};
