/* eslint-disable @typescript-eslint/no-unused-vars */
import { styled } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { PriceListProps, PriceProps } from './PriceWatch';
import {
  //removeUpdatedPrice,
  updatedPriceList
} from '../../Reducers/DefaultReducer';
import { useRef } from 'react';
import store from '../../Redux/Store';

export enum PriceTemplates {
  'SIMPLE',
  'INDICES',
  'TICKER',
  'POSITION'
}
interface WSPriceProps {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  security_id?: number;
  quantity?: number;
  showDifference?: boolean;
  boughtPrice?: number;
  currency?: string;
  showCurrency?: boolean;
  template?: PriceTemplates;
}

const PriceWrapper = styled.span`
  .priceDisplay {
    animation: blink 1s infinite;
  }
  .green {
    color: green;
  }

  .red {
    color: red;
  }

  .change {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
  }
  .priceWithoutSymbol {
    display: block;
  }

  .indices {
    display: flex;
    align-items: center;
    justify-content: center;
    .price {
      font-size: 24px;
      animation: blink 1s 1;
    }
    .highLow {
      font-size: 14px;
      b {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
      }
    }
  }
`;

export const WSPrice = (props: WSPriceProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    security_id = 0,
    showDifference = true,
    quantity = 1,
    boughtPrice = 0,
    currency = 'INR',
    showCurrency = true,
    template = PriceTemplates.SIMPLE
  } = props;
  const dispatch = useDispatch();
  const loadTimes = useRef(0);

  const priceWatch: PriceListProps = useSelector(updatedPriceList);

  const storeState = store.getState();
  const priceDetail = priceWatch[security_id]?.price;
  
  const { change = 0, changePercent = 0 } =
    (priceDetail && (priceDetail as unknown as PriceProps)) || {};
  const price = Number(priceDetail?.price) || 0;


  if (security_id === 13) {
    loadTimes.current = loadTimes.current + 1;
  }

  const differenceAmount =
    price > 0 ? price * quantity - boughtPrice * quantity : 0;
  const differenceAmountPercent =
    price > 0 ? ((price * quantity) / (boughtPrice * quantity)) * 100 - 100 : 0;

  const PriceDisplay = ({ showCurrency = true }) => {
    const returnPrice = showCurrency
      ? (price * quantity).toLocaleString('en-IN', {
          style: 'currency',
          currency: currency || 'INR',
          minimumFractionDigits: 2
        })
      : Number(price && price.toFixed(2)).toLocaleString('en-IN', {
          minimumFractionDigits: 2
        });
    return returnPrice;
  };

  const ChangeDisplay = () => {
    return (
      <span
        className={`${Number(change) > 0 && 'green'} ${
          Number(change) < 0 && 'red'
        } col small change`}
      >
        {Number(change).toFixed(2)}&nbsp;(
        {Number(changePercent).toFixed(2)}
        %)
      </span>
    );
  };

  const SIMPLE = () => {
    return (
      <span className="row">
        <span className="col priceDisplay">
          <PriceDisplay />
        </span>
        <span className="col">
          <ChangeDisplay />
        </span>
      </span>
    );
  };
  const POSITION = () => {
    return (
      <span>
        <PriceDisplay />
        <br />
        <ChangeDisplay />
      </span>
    );
  };
  const TICKER = () => {
    return (
      <>
        {change > 0 && <span className="diff green">&#9650;</span>}
        {change < 0 && <span className="diff red">&#9660;</span>}
        <PriceDisplay showCurrency={false} />
        <ChangeDisplay />
      </>
    );
  };
  const INDICES = () => {
    return (
      <span className="row indices">
        <span className="col">
          <div className="price">
            <PriceDisplay showCurrency={false} />
          </div>
          <ChangeDisplay />
        </span>
        {/* <span className='col'>
          <table className='table table-condensed highLow table-bordered'>
            <tbody>
            <tr>
              <td><b>Open</b></td>
              <td>{Number(priceDetail?.open || 0).toFixed(2)}</td>
            </tr>
            <tr>
              <td><b>High</b></td>
              <td>{Number(priceDetail?.high || 0).toFixed(2)}</td>
            </tr>
            <tr>
              <td><b>Low</b></td>
              <td>{Number(priceDetail?.low || 0).toFixed(2)}</td>
            </tr>
            </tbody>
          </table>
        </span> */}
      </span>
    );
  };

  const selectTemplate = () => {
    switch (template) {
      case PriceTemplates.SIMPLE: {
        return <SIMPLE></SIMPLE>;
      }
      case PriceTemplates.INDICES: {
        return <INDICES></INDICES>;
      }
      case PriceTemplates.TICKER: {
        return <TICKER></TICKER>;
      }
      case PriceTemplates.POSITION: {
        return <POSITION></POSITION>;
      }
      default:
        return <SIMPLE></SIMPLE>;
    }
  };

  return (
    <PriceWrapper className="priceWrapper">{selectTemplate()}</PriceWrapper>
  );
};
