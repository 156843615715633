import styled from 'styled-components';

export const StyledTopList = styled.div`
  display: flex;
  .listContainer {
    display: flex;
    flex: 1;
    gap: 20px;
  }

  .listRow {
    border: 1px solid #ccc;
    margin: 5px 0px;
    flex: 1;

    .stockDetail {
      font-size: 0.8em;
      padding: 5px 5px;
      border-bottom: 1px solid #ccc;
    }

    h5 {
      font-size: 0.7em;
      text-transform: uppercase;
      padding: 10px;
      font-weight: 600;
      border-bottom: 2px solid;;
    }
    &.gainer {
      h5 {
        color: darkgreen;
      }
    }

    &.loser {
      h5 {
        color: darkred;
      }
    }
  }

  /* Responsive layout - makes a one column layout instead of a two-column layout */
  @media (max-width: 800px) {
    .listContainer {
      flex-direction: column;
    }
  }
`;
