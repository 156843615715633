import { createSlice, current } from '@reduxjs/toolkit';
import { WatchListProps } from '../components/WatchList/WatchList';
import {
  PriceListProps,
  PriceWatchProps
} from '../components/common/PriceWatch';
import { PositionsProps } from '../components/Positions/Positions';
import { AddPositionDialogProps } from '../components/Positions/AddPositionDialog';

export interface DefaultState {
  watchList?: WatchListProps[];
  priceWatch?: PriceWatchProps[];
  positions?: PositionsProps[];
  openAddPositionDialog?: AddPositionDialogProps;
  lastUpdated: number | null;
  reloadPositions: boolean;
  instruments?: PriceWatchProps[];
  priceList: PriceWatchProps[];
  updatedPriceList: PriceListProps;
}

export const DefaultReducer = createSlice({
  name: 'Default',
  initialState: {
    watchList: [],
    priceWatch: [],
    positions: [],
    openAddPositionDialog: { open: false, scripId: 0 },
    reloadPositions: false,
    lastUpdated: null,
    instruments: [],
    priceList: [],
    updatedPriceList: {}
  } as DefaultState,
  reducers: {
    addPositionDialog: (state, action) => {
      state.openAddPositionDialog = action.payload;
    },

    updateReloadPositions: (state, action) => {
      state.reloadPositions = action.payload;
    },

    updateWatchList: (state, action) => {
      state.watchList = action.payload;
    },

    addStockToWatchList: (state, action) => {
      state.watchList?.unshift(action.payload.watchList);
    },

    deleteStockFromWatchList: (state, action) => {
      const watchList = [...current(state.watchList || [])] || [];

      if (watchList && Array.isArray(watchList)) {
        const index = watchList.findIndex(
          (a: WatchListProps) => a.security_id === action.payload.security_id
        );
        if (index > -1) {
          watchList.splice(index, 1);
          state.watchList = watchList;
        }
      }
    },

    updatePriceWatch: (state, action) => {
      state.priceWatch = action.payload;
    },

    addToPriceWatch: (state, action) => {
      state.priceWatch?.unshift(action.payload);
    },

    updatePositions: (state, action) => {
      state.positions = action.payload;
    },

    removeUpdatedPrice: (state, action) => {
      const id = action.payload;
      delete state.updatedPriceList[id];
    },

    updatePriceList: (state, action) => {
      const payload = action.payload;
      const priceList: PriceWatchProps[] = [...current(state.priceList || [])] || [];
      if (!state.updatedPriceList) {
        state.updatedPriceList = {};
      }
      if (Array.isArray(payload)) {
        const priceObj: PriceListProps = {...current(state.updatedPriceList || {})};
        
        payload.map((watch) => {
          const stockDetail: PriceWatchProps = [...current(state.instruments || [])].filter(stock => stock.scripId === watch?.security_id)[0];
          const priceDetail = {
            id: watch?.security_id,
            price: watch?.last_price,
            changePercent: watch?.change_percent,
            change: watch?.change_absolute,
            high: watch?.high,
            low: watch?.low,
            mode: watch?.mode,
            open: watch?.open
          };
          const stockDetailObj: PriceWatchProps = {...stockDetail, price: priceDetail, depthPacket: watch?.depthPacket};
          priceObj[watch.security_id] = {...stockDetail, price: priceDetail};
          const index = priceList.findIndex(p => p.scripId === watch?.security_id);
          if(index > -1) {
            priceList[index] = stockDetailObj;
          } else {
            priceList.push(stockDetailObj);
          }

          if(watch?.last_update_time) {
            state.lastUpdated = watch?.last_update_time;
          }
          
          return null;
        });
        state.updatedPriceList = priceObj;
        state.priceList = priceList;
      }
    },

    updatePrice: (state, action) => {
      // const payload = action.payload;
      // if (!state.priceList) {
      //   state.priceList = {};
      // }
      // if (!state.updatedPriceList) {
      //   state.updatedPriceList = {};
      // }
      // state.priceList[payload.id] = action.payload;
      // state.updatedPriceList[payload.id] = action.payload;
      // const payload = action.payload;
      // const priceWatch = [...current(state.priceWatch || [])];

      // const index = priceWatch?.findIndex(
      //   (watch) => watch.scripId === payload.id
      // );

      // const instrument = [...current(state.instruments || [])].filter(instrument => instrument.scripId === payload.id)[0] || {};

      // const price = {
      //   price: payload?.price,
      //   changePercent: payload?.changePercent,
      //   change: payload?.change,
      //   high: payload?.high,
      //   low: payload?.low,
      //   mode: payload?.mode,
      //   open: payload?.open
      // };

      // if (index > -1) {
      //   const updatedPriceObj = {
      //     ...priceWatch[index],
      //     ...instrument,
      //     price: price
      //   };
      //   if (state.priceWatch && state.priceWatch.length > 0) {
      //     state.priceWatch[index] = updatedPriceObj;
      //   }
      // } else {
      //   priceWatch.push({
      //     ...instrument,
      //     price: price,
      //     scripId: payload.id
      //   });
      //   state.priceWatch = [...priceWatch] || [];
      // }
    },

    addPosition: (state, action) => {
      if (!state.positions) {
        state.positions = [];
      }
      state.positions?.push(action.payload);
    },

    updateLastUpdated: (state, action) => {
      state.lastUpdated = action.payload;
    },

    updateInstruments: (state, action) => {
      state.instruments = action.payload;
    }
  }
});

export const {
  addStockToWatchList,
  deleteStockFromWatchList,
  updatePriceWatch,
  addToPriceWatch,
  updatePositions,
  addPosition,
  addPositionDialog,
  updateLastUpdated,
  updateWatchList,
  updateReloadPositions,
  updatePrice,
  updateInstruments,
  removeUpdatedPrice,
  updatePriceList
} = DefaultReducer.actions;

type AnyType = any;
export const watchList = (state: AnyType) => state.DefaultReducer.watchList;
export const instrumentsList = (state: AnyType) =>
  state.DefaultReducer.instruments;
export const priceWatchList = (state: AnyType) =>
  state.DefaultReducer.priceWatch;
export const positionsList = (state: AnyType) => state.DefaultReducer.positions;
export const openAddPositionDialog = (state: AnyType) =>
  state.DefaultReducer.openAddPositionDialog;
export const DataLastUpdated = (state: AnyType) =>
  state.DefaultReducer.lastUpdated;
export const reloadPositions = (state: AnyType) =>
  state.DefaultReducer.reloadPositions;

export const priceList = (state: AnyType) => state.DefaultReducer.priceList;
export const updatedPriceList = (state: AnyType) =>
  state.DefaultReducer.updatedPriceList;

export default DefaultReducer.reducer;
