const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://mdstox.onrender.com/'
    : 'http://localhost:3000/';

export const URL = {
  allSymbols: baseURL + 'symbols',
  watchlist: baseURL + 'watchlist',
  addToWatchList: baseURL + 'addToWatchList',
  positions: baseURL + 'positions',
  addPosition: baseURL + 'addPosition'
};
