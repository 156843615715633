import { CircularProgress, Popper, styled } from '@mui/material';
import { fetchAllSymbols } from '../../services/Stocks';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Fragment, useEffect, useMemo, useState } from 'react';
// import { ListboxComponent } from './SymbolsListBox';
import debounce from 'lodash.debounce';
import {
  instrumentsList,
  updateInstruments
} from '../../Reducers/DefaultReducer';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    ul: {
      padding: 0,
      margin: 0,
      li: {
        fontFamily: 'Tahoma',
        fontSize: '14px'
      }
    }
  }
});

export interface SymbolsProps {
  onSelect?: (arg: string) => void;
}

export const Symbols = (props: SymbolsProps) => {
  const { onSelect } = props;
  const [open, setOpen] = useState(false);
  const instruments = useSelector(instrumentsList) || [];
  // const loading = open && instruments && instruments.length === 0;
  const loading = open && false;
  const dispatch = useDispatch();

  // const [setValue] = useState();

  const fetchSymbols = (query?: string) => {
    fetchAllSymbols(query).then((result: any) => {
      if (result) {
        dispatch(updateInstruments(result));
      }
    });
  };

  useEffect(() => {
    fetchSymbols();
  }, []);

  const debouncedResults = useMemo((value?: string) => {
    return debounce(fetchSymbols, 300);
  }, []);

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      fetchSymbols();
    })();
  }, [loading]);

  // if (!symbolsList || symbolsList.length === 0) {
  //   fetchSymbols();
  // }

  const handleChange = (_event: any, newValue: any) => {
    // setValue(newValue);
    onSelect && onSelect(newValue);
  };

  return (
    <Autocomplete
      disableListWrap
      clearOnBlur={false}
      clearOnEscape={false}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      //loading={loading}
      // PopperComponent={StyledPopper}
      // ListboxComponent={ListboxComponent}
      options={[...instruments]}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Select Stock to add to WatchList"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            )
          }}
        />
      )}
      onInputChange={(_, value) => debouncedResults(value)}
      // renderOption={(props, option, state) =>
      //   [props, option, state.index] as React.ReactNode
      // }
      onChange={handleChange}
      getOptionLabel={(option) => option.symbol}
      isOptionEqualToValue={(option, value) => option.symbol === value.symbol}
    />
  );
};
