import { Dialog } from '@mui/material';
import { styled } from 'styled-components';

export const StyledPositions = styled.div`
  .MuiPaper-root {
    margin-top: auto;
    min-height: auto;

    h5 {
      margin-bottom: 10px;
    }
  }
  .MuiAlert-message {
    width: 100%;
    overflow: hidden;
  }

  .green {
    color: green;
  }

  .red {
    color: red;
  }

  .summary {
    background-color: lightsteelblue;
  }

  .position {
    border: 1px solid #ccc;
    font-size: 14px;
    min-height: auto;
    .cardHeader {
      padding: 5px;
      .header {
        font-size: 14px;
        b {
          font-size: 14px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
        }
      }
    }
    .MuiCardContent-root {
      padding: 0px;
    }
    table {
      margin: 0px;
      td {
        border: none;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        padding: 0px;
      }
    }

    .actions {
      display: none;
    }
    .currentAmt {
      border-left: 1px solid #ccc;
      .priceWrapper {
        display: inline-block;
      }
    }
  }
  .position:hover {
    position: relative;
    .actions {
      display: block;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
`;

export const StyledDialog = styled(Dialog)`
  h5 {
    margin-bottom: 40px;
  }
`;
