import React from 'react';
import reportWebVitals from './reportWebVitals';
import Header from './components/includes/Header/Header';
import './index.scss';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import store from './Redux/Store';
import ReactDOM from 'react-dom';

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Header />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path='stock/:stockID' element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <div id="modalLayout"></div>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
