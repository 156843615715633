import React, { FC, useState } from 'react';
import { HomeWrapper } from './Home.styled';
import WatchList from '../WatchList/WatchList';
import { PriceWatch } from '../common/PriceWatch';
import { Indices } from '../Indices/Indices';
import { Positions } from '../Positions/Positions';
import {
  AddPositionDialog,
  AddPositionDialogProps
} from '../Positions/AddPositionDialog';
import { openAddPositionDialog } from '../../Reducers/DefaultReducer';
import { useSelector } from 'react-redux';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import WatchLater from '@mui/icons-material/WatchLater';
import List from '@mui/icons-material/List';
import TableChart from '@mui/icons-material/TableChart';
import { useParams } from 'react-router-dom';
import { StockDetail } from '../StockDetail/StockDetails';
import { Ticker } from '../Marquee/Marquee';
import HomeIcon from '@mui/icons-material/Home';
import { TopList } from '../Top/TopList';
// import { AllPrice } from '../AllPrice';

interface HomeProps {
  dummy?: string;
}

const Home: FC<HomeProps> = () => {
  const positionDialog: AddPositionDialogProps = useSelector(
    openAddPositionDialog
  ) || { open: false };

  const [tabValue, setTabValue] = useState('home');
  const { stockID } = useParams();

  const handleTabChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setTabValue(value);
  };

  return (
    <HomeWrapper data-testid="Home" className="container">
      <PriceWatch></PriceWatch>
      <Ticker></Ticker>
      <div className="row mt-2">
        <div
          className={`${
            tabValue === 'watchList' ? 'd-sm-block' : 'd-none'
          } d-md-block col-md-3 col-sm-12 border`}
        >
          <WatchList></WatchList>
        </div>
        <div className="col-md-9 col-sm-12">
          <div className={`${tabValue !== 'indices' && 'd-none'} d-md-block`}>
            <Indices></Indices>
          </div>
          <div className={`${tabValue !== 'home' && 'd-none'} d-md-block mb-4`}>
            <TopList></TopList>
          </div>
          {/* <div className={`${tabValue !== 'list' && 'd-none'} d-md-block`}>
            <AllPrice></AllPrice>
          </div> */}
          <div className={`${tabValue !== 'positions' && 'd-none'} d-md-block`}>
            {stockID ? <StockDetail /> : <Positions></Positions>}
          </div>
          <AddPositionDialog {...positionDialog}></AddPositionDialog>
        </div>
      </div>
      <div className="d-block d-sm-none">
        <Paper
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={tabValue}
            onChange={handleTabChange}
          >
            <BottomNavigationAction
              icon={<HomeIcon />}
              label="Home"
              value="home"
            />
            <BottomNavigationAction
              icon={<WatchLater />}
              label="Watchlist"
              value="watchList"
            />
            <BottomNavigationAction
              icon={<List />}
              label="Indices"
              value="indices"
            />
            <BottomNavigationAction
              icon={<TableChart />}
              label="Positions"
              value="positions"
            />
          </BottomNavigation>
        </Paper>
      </div>
    </HomeWrapper>
  );
};

export default Home;
