/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports */
import { Tab, Tabs } from '@mui/material';
import { StyledTopList } from './TopList.styled';
import { useState } from 'react';
import { PriceWatchProps, ScripTypeEnum } from '../common/PriceWatch';
import { useSelector } from 'react-redux';
import { priceList } from '../../Reducers/DefaultReducer';
import { PriceTemplates, WSPrice } from '../common/WSPrice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const TopList = () => {
  const [value, setValue] = useState(0);
  const _priceList: PriceWatchProps[] = useSelector(priceList);

  return (
    <StyledTopList>
      <div className="listContainer">
        <div className="listRow gainer">
          <h5>Top Gainers</h5>
          <div className="lists">
            {[..._priceList]
              .filter(
                (stock) =>
                  stock.scripType?.toString() ===
                    ScripTypeEnum[ScripTypeEnum.EQUITY] &&
                  (stock.price?.change || 0) > 0
              )
              .sort(
                (a, b) =>
                  (b?.price?.changePercent || 0) -
                  (a?.price?.changePercent || 0)
              )
              .slice(0, 5)
              .map((stock) => {
                return (
                  <div key={stock.scripId} className="stockDetail">
                    {stock.name}
                    <WSPrice security_id={stock.scripId || 0} template={PriceTemplates.SIMPLE} />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="listRow loser">
          <h5>Top Losers</h5>
          <div className="lists">
            {[..._priceList]
              .filter(
                (stock) =>
                  stock.scripType?.toString() ===
                  ScripTypeEnum[ScripTypeEnum.EQUITY]
              )
              .sort(
                (a, b) =>
                  (a?.price?.changePercent || 0) -
                  (b?.price?.changePercent || 0)
              )
              .slice(0, 5)
              .map((stock) => {
                return (
                  <div key={stock.scripId} className="stockDetail">
                    {stock.name}
                    <WSPrice security_id={stock.scripId || 0} template={PriceTemplates.SIMPLE} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </StyledTopList>
  );
};
