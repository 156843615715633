import { addPosition, addStockToWatchList, updatePositions } from '../Reducers/DefaultReducer';
import store from '../Redux/Store';

// eslint-disable-next-line @typescript-eslint/no-unused-vars

export const performAPIAction = (url: string, type = 'GET', body?: any) => {
  const splitURL = url.split('/');
  const state = store.getState();

  const { positions, watchList } = state.DefaultReducer;
  console.log(url, type, body);

  let returnValue: any = { data: [] };
  switch (splitURL[splitURL.length - 1]) {
    case 'positions':
      if (type === 'GET') {
        returnValue = { data: positions?.filter((pos) => pos.status !== 1) };
      }
      break;
    case 'addPosition':
      if (type === 'POST') {
        store.dispatch(
          addPosition({ ...body, id: positions?.length || 0 + 1 })
        );
      }
      break;
    case 'symbols':
      if (type === 'GET') {
        const url = '/data/allSymbols.json';
        return fetch(url, {
          method: type,
          mode: 'cors',
          body: JSON.stringify(body),
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            return response
              .json()
              .then((data) => {
                return {data: data};
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log('Error', err.message);
            return 'Error: ' + err.message;
          });
      }
      break;
    case 'watchlist':
      if (type === 'GET') {
        returnValue = { data: watchList };
      }
      break;
    case 'addToWatchList': 
    if (type === 'POST') {
      store.dispatch(
        addStockToWatchList({ ...body, id: watchList?.length || 0 + 1 })
      );
    }
    break;
    default:
      if (type === 'PUT') {
        const positionID = parseInt(splitURL[splitURL.length - 1]);
        const tempPositions = [...(positions || [])];
        const index = tempPositions?.findIndex((pos) => pos.id === positionID);
        tempPositions[index] = { ...body, status: 1 };
        store.dispatch(updatePositions(tempPositions));
      }
  }
  return new Promise((resolve, reject) => {
    resolve(returnValue);
  });
  // return fetch(url, {
  //   method: type,
  //   mode: 'cors',
  //   body: JSON.stringify(body),
  //   headers: {
  //     // eslint-disable-next-line @typescript-eslint/naming-convention
  //     'Content-Type': 'application/json'
  //   }
  // })
  //   .then((response) => {
  //     return response
  //       .json()
  //       .then((data) => {
  //         return data;
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   })
  //   .catch((err) => {
  //     console.log('Error', err.message);
  //     return 'Error: ' + err.message;
  //   });
};
