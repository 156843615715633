import { PositionsProps } from '../components/Positions/Positions';
import { performAPIAction } from './API';
import { URL } from './URLs';

export const INDICES = [
  
  { symbol: 13, displayName: 'Nifty 50', main: true },
  { symbol: 51, displayName: 'Sensex' },
  { symbol: 25, displayName: 'Nifty Bank', main: true },
  { symbol: 29, displayName: 'Nifty IT'},
  { symbol: 27, displayName: 'Nifty Finance'},
  { symbol: 21, displayName: 'India Vix', main: false },
];

export const INDICES_SYMBOLS = INDICES.map(ind => ind.symbol);

export const AppendIgnoreList = [...INDICES_SYMBOLS, 'HPE'];
type ResultType = any;

export const fetchAllSymbols = (query?: string) => {
  // const url = URL.allSymbols + (query ? '?search=' + query : '');
  const url = URL.allSymbols;

  return performAPIAction(url).then((result: ResultType) => {
    return new Promise((resolve, reject) => {
      resolve(result.data);
    });
  });
};

export const fetchWatchlist = () => {
  const url = URL.watchlist;

  return performAPIAction(url).then((result: ResultType) => {
    return new Promise((resolve, reject) => {
      resolve(result.data);
    });
  });
};

export const addToWatchList = (watchlist: ResultType) => {
  const url = URL.addToWatchList;

  return performAPIAction(url, 'POST', watchlist).then((result: ResultType) => {
    return new Promise((resolve, reject) => {
      resolve(result.data);
    });
  });
};

export const deleteWatchList = (id: number) => {
  const url = URL.watchlist + '/' + id;

  return performAPIAction(url, 'DELETE').then((result: ResultType) => {
    return new Promise((resolve, reject) => {
      resolve(result.data);
    });
  });
};

export const fetchPositions = () => {
  const url = URL.positions;

  return performAPIAction(url).then((result: ResultType) => {
    return new Promise((resolve, reject) => {
      resolve(result.data);
    });
  });
};

export const addPosition = (position: PositionsProps) => {
  const url = URL.addPosition;

  return performAPIAction(url, 'POST', position).then((result: ResultType) => {
    return new Promise((resolve, reject) => {
      resolve(result.data);
    });
  });
};

export const exitPosition = (id: number) => {
  const url = URL.positions + '/' + id;

  return performAPIAction(url, 'PUT').then((result: ResultType) => {
    return new Promise((resolve, reject) => {
      resolve(result.data);
    });
  });
};
