import styled from 'styled-components';
import { PriceWatchProps } from './PriceWatch';

type DepthPacketProps = Pick<PriceWatchProps, 'depthPacket'>;

const StyledPactkets = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  h5 {
    margin-bottom: 0px;
    font-size: 0.9em;
  }
  table {
    font-size: 0.7em;
  }
`;

export const DepthPacket = (props: DepthPacketProps) => {
  const packets = props as any;
  return (
    <StyledPactkets>
      <div>
        <h5>Buy</h5>
        <table className="table table-bordered table-condensed">
          <thead>
            <tr>
              <th>Order</th>
              <th>Price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(props).map((depth: string) => {
              return (
                <tr key={depth}>
                  <td>{packets[depth].buy_order}</td>
                  <td>{packets[depth].buy_price}</td>
                  <td>{packets[depth].buy_quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <h5>Sell</h5>
        <table className="table table-bordered table-condensed">
          <thead>
            <tr>
              <th>Order</th>
              <th>Price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(props).map((depth: string) => {
              return (
                <tr key={depth}>
                  <td>{packets[depth].sell_order}</td>
                  <td>{packets[depth].sell_price}</td>
                  <td>{packets[depth].sell_quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </StyledPactkets>
  );
};
