/* eslint-disable @typescript-eslint/naming-convention */
import { useSelector } from 'react-redux';
import { instrumentsList } from '../../Reducers/DefaultReducer';
import { PriceTemplates, WSPrice } from '../common/WSPrice';
import { StyledMarquee } from './Marquee.styled';
import { PriceWatchProps } from '../common/PriceWatch';

export const Ticker = () => {
  const tickerList = useSelector(instrumentsList) || [];
  return (
    <StyledMarquee pauseOnClick={true} pauseOnHover={true}>
      {tickerList.map((tick: PriceWatchProps) => {
        return (
          <span key={tick.scripId || 0} className="ticker">
            <span className='title'>{tick.name} &nbsp;{' '}</span>
            <WSPrice
              security_id={tick.scripId || 0}
              template={PriceTemplates.TICKER}
            />
          </span>
        );
      })}
    </StyledMarquee>
  );
};
