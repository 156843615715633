/* eslint-disable @typescript-eslint/naming-convention */
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPositionDialog,
  priceList,
  updateReloadPositions,
  updatedPriceList
} from '../../Reducers/DefaultReducer';
import { useState } from 'react';
import { PositionsProps } from './Positions';
import { PriceListProps, PriceWatchProps } from '../common/PriceWatch';
import { PriceTemplates, WSPrice } from '../common/WSPrice';
import { StockProps } from '../WatchList/WatchList';
import { addPosition } from '../../services/Stocks';
import { StyledDialog } from './Positions.styled';
import { DepthPacket } from '../common/DepthPacket';

export interface AddPositionDialogProps
  extends Pick<StockProps, 'exchange' | 'segment'>,
    Pick<PriceWatchProps, 'depthPacket'> {
  open: boolean;
  scripId?: number;
  name?: string;
  price?: number;
  lotSize?: number;
}

export const AddPositionDialog = (props: AddPositionDialogProps) => {
  const { open, scripId = 0, lotSize = 1 } = props;
  const stockDetail: PriceWatchProps = useSelector(priceList).filter(
    (stock: PriceWatchProps) => stock.scripId === scripId
  )[0] || {};
  const priceWatch: PriceListProps = useSelector(updatedPriceList);
  const [limitPrice, setLimitPrice] = useState(0);
  const [error, setError] = useState(false);
  const marketPrice = priceWatch[scripId]?.price.price;
  const [quantity, setQuantity] = useState(1);
  const [priceType, setPriceType] = useState('market');
  const dispatch = useDispatch();
  //   const useStyles:any = makeStyles({
  //     topScrollPaper: {
  //       alignItems: 'flex-end'
  //     },
  //     topPaperScrollBody: {
  //       verticalAlign: 'bottom'
  //     }
  //   });

  const handleCancel = () => {
    dispatch(addPositionDialog(false));
  };

  const handleSubmit = () => {
    const obj: PositionsProps = {
      name: stockDetail?.name,
      security_id: scripId || 0,
      quantity: quantity * lotSize,
      boughtPrice: priceType === 'market' ? marketPrice : limitPrice
    };
    addPosition(obj).then((result) => {
      setError(false);
      if (result) {
        dispatch(updateReloadPositions(true));
        handleCancel();
      } else {
        setError(true);
      }
    });
  };

  const handleQuantityChange = (val: number) => {
    setQuantity(val);
  };

  const handleLimitPriceChange = (val: number) => {
    setLimitPrice(val);
  };

  const handlePriceTypeChange = (val: string) => {
    setPriceType(val);
  };

  return (
    <div>
      <StyledDialog
        aria-labelledby="simple-dialog-title"
        open={open}
        scroll="paper"
        // classes={{
        //   scrollPaper: classes.topScrollPaper,
        //   paperScrollBody: classes.topPaperScrollBody
        // }}
      >
        <DialogContent>
          <h5>
            {stockDetail?.name} <br />
          </h5>
          {/* <div>
            Lot Size: {lotSize} <br />
            <br />
          </div> */}
          {error && (
            <>
              <Alert severity="error">
                Error occurred. Please try again later.
              </Alert>{' '}
              <br />
            </>
          )}
          <div className="row">
            <div className="col">
              <TextField
                type="number"
                required
                id="outlined-required"
                label="Quantity"
                value={quantity}
                onChange={(e) =>
                  handleQuantityChange(
                    Number((e.target as HTMLInputElement).value)
                  )
                }
              />
            </div>
            <div className="col">
              <RadioGroup
                defaultValue="market"
                row
                name="price"
                value={priceType}
                onChange={(event) => handlePriceTypeChange(event.target.value)}
              >
                <FormControlLabel
                  value="market"
                  control={<Radio />}
                  label="Market"
                />
                <FormControlLabel
                  value="limit"
                  control={<Radio />}
                  label="Limit"
                />
              </RadioGroup>
              {priceType === 'market' ? (
                <WSPrice security_id={scripId || 0} template={PriceTemplates.SIMPLE} />
              ) : (
                <TextField
                  type="number"
                  value={limitPrice}
                  onChange={(event) =>
                    handleLimitPriceChange(
                      Number((event.target as HTMLInputElement).value)
                    )
                  }
                />
              )}
            </div>
          </div>
          <DepthPacket {...stockDetail?.depthPacket}></DepthPacket>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant={'contained'} onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </StyledDialog>
    </div>
  );
};
