import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  .navbar {
    height: 50px;
    background-color: darkslateblue;
    
    .navbar-brand {
      color: #fff;
      text-transform: uppercase;
    }

    .lastUpdated {
      color: white;
      font-size: 0.7em;
    }
  }
`;
