/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { WatchListWrapper } from './WatchList.styled';
import {
  addPositionDialog,
  updateWatchList,
  watchList
} from '../../Reducers/DefaultReducer';
import { Symbols } from '../common/Symbols';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddPositionDialogProps } from '../Positions/AddPositionDialog';
import {
  addToWatchList,
  deleteWatchList,
  fetchWatchlist
} from '../../services/Stocks';
import { Alert } from '@mui/material';
import { PriceWatchProps } from '../common/PriceWatch';
import { WSPrice } from '../common/WSPrice';

export interface StockProps {
  security_id?: number;
  symbol?: string;
  name?: string;
  series?: string;
  tick_size?: number;
  lot_size?: number;
  instrument_type?: string;
  segment?: string;
  exchange?: string;
  upper_limit?: number;
  lower_limit?: number;
  expiry_date?: string;
  strike_price?: string;
  freeze_quantity?: number;
}
export type WatchListProps = StockProps;

export interface WatchListGlobalProps {
  customName?: string;
  list?: WatchListProps[];
}

const WatchList: FC<WatchListProps> = () => {
  const [watchLists, setWatchLists] = useState(useSelector(watchList) || []);
  const [error, setError] = useState(false);

  const fetchList = (query?: string) => {
    fetchWatchlist()
      .then((result: any) => {
        if (result) {
          setWatchLists([...result]);
          dispatch(updateWatchList([...result]));
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchList();
  }, []);

  const dispatch = useDispatch();

  const handleSymbolSelect = (selectedSymbol: any) => {
    if (selectedSymbol) {
      addToWatchList({
        watchList: selectedSymbol
      }).then(() => {
        fetchList();
      });
    }
  };

  const handleDeleteStock = (security_id: number) => {
    // dispatch(deleteStockFromWatchList({ security_id: security_id }));
    deleteWatchList(security_id).then(() => {
      fetchList();
    });
  };

  const handleBuyStock = (watch: PriceWatchProps) => {
    const { scripId } = watch;
    const obj: AddPositionDialogProps = {
      open: true,
      scripId: scripId || 0
    };
    dispatch(addPositionDialog(obj));
  };

  return (
    <WatchListWrapper data-testid="WatchList">
      <div className="row topIndices">
        <div className="col-6 border-right">
          <span className="indices">Nifty</span>
          <WSPrice security_id={13} showCurrency={false}></WSPrice>
        </div>
        <div className="col-6">
          <span className="indices">Nifty Bank</span>
          <WSPrice security_id={25} showCurrency={false}></WSPrice>
        </div>
      </div>
      <div className="form-group pt-2">
        <Symbols onSelect={handleSymbolSelect}></Symbols>
      </div>
      {error && (
        <>
          <Alert severity="error">
            Error occurred while getting watchlist.
          </Alert>{' '}
          <br />
        </>
      )}
      <table className="table table-bordered watchListTable">
        <tbody>
          {watchLists?.map((watch: PriceWatchProps) => {
            return (
              <tr key={watch?.name}>
                <td>
                  {watch?.name} <br />
                  <WSPrice security_id={watch.scripId || 0} />
                  <div className="watchListActions">
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleBuyStock(watch)}
                    >
                      B
                    </button>
                    <button
                      className="btn-sm btn btn-warning"
                      onClick={() => handleDeleteStock(watch?.scripId || 0)}
                    >
                      <DeleteIcon></DeleteIcon>
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </WatchListWrapper>
  );
};

export default WatchList;
