/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-inline-styles/no-inline-styles */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import {
  addPositionDialog,
  positionsList,
  reloadPositions,
  updatePositions,
  updateReloadPositions,
  updatedPriceList
} from '../../Reducers/DefaultReducer';
import { AddPositionDialogProps } from './AddPositionDialog';
import { Alert, Card, CardContent, Typography } from '@mui/material';
import { StyledPositions } from './Positions.styled';
import { PriceListProps } from '../common/PriceWatch';
import { PriceTemplates, WSPrice } from '../common/WSPrice';
import { StockProps } from '../WatchList/WatchList';
import { useEffect, useState } from 'react';
import { exitPosition, fetchPositions } from '../../services/Stocks';

export interface PositionsProps extends StockProps {
  id?: number;
  quantity?: number;
  boughtPrice?: number;
  investedAmount?: number;
  currentAmount?: number;
  profitLoss?: number;
  dateTime?: Date;
  /** Created for calculating average */
  count?: number;
  status?: number;
}
export const Positions = () => {
  const [positions, setPositions] = useState<PositionsProps[]>(
    useSelector(positionsList) || []
  );
  const [error, setError] = useState(false);
  const priceWatch: PriceListProps = useSelector(updatedPriceList);
  const reload = useSelector(reloadPositions) || false;
  const dispatch = useDispatch();

  const fetchList = (query?: string) => {
    fetchPositions().then((result: any) => {
      if (result) {
        setPositions([...result]);
        dispatch(updatePositions([...result]));
      } else {
        setError(true);
      }
    });
  };

  if (reload) {
    dispatch(updateReloadPositions(false));
    fetchList();
  }

  useEffect(() => {
    fetchList();
  }, []);

  const totalInvested = positions.reduce((accumulator, object) => {
    return accumulator + (object.boughtPrice || 0) * (object.quantity || 0);
  }, 0);

  const handleExit = (id: number) => {
    exitPosition(id).then(() => fetchList());
  };

  const handleAdd = (id: number) => {
    const obj: AddPositionDialogProps = { open: true, scripId: id, price: 100 };
    dispatch(addPositionDialog(obj));
  };

  const getTotalCurrentAmount = () => {
    let accumulator = 0;
    [...positions].map((object) => {
      const currentPrice =
        priceWatch[object.security_id || 0]?.price?.price || 0;
      accumulator = accumulator + currentPrice * (object.quantity || 0);

      return null;
    });
    return accumulator || 0;
  };

  const getTotalPL = () => {
    // const total = positions.reduce((accumulator, object) => {
    //   return accumulator + (object.profitLoss || 0);
    // }, 0);
    const PL = getTotalCurrentAmount() - totalInvested;
    return (
      <span className={PL > 0 ? 'green' : 'red'}>
        {PL.toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR'
        }) || 0}
      </span>
    );
  };

  // const handleExitAll = () => {
  //   const tempPositions = [...positions];
  //   tempPositions.map((pos) => {
  //     return {...pos, status: 1};
  //   });
  //   dispatch(updatePositions([]));
  // };

  return (
    <StyledPositions>
      <h4>My Positions</h4>
      {error && (
        <>
          <Alert severity="error">
            Error occurred while getting your positions.
          </Alert>{' '}
        </>
      )}
      <div className="summary p-2 small border mb-2">
        <b>Overall Summary</b>
        <div className="row">
          <div className="col mr-auto">
            <b>Invested:</b>{' '}
            {totalInvested.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR'
            })}
          </div>
          <div className="col">
            <b>Current:</b>{' '}
            {getTotalCurrentAmount().toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR'
            })}
          </div>
          <div className="col">
            <b>P&L:</b> {getTotalPL()}
          </div>
        </div>
      </div>
      <div>
        {/* <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={handleExitAll}
        >
          Exit ALL
        </button> */}
      </div>
      <div className="row">
        {positions.map((position: PositionsProps, index) => {
          const {
            id = 0,
            security_id = 0,
            quantity = 0,
            boughtPrice = 0,
            currentAmount,
            name,
            segment
          } = position;
          const invested = (boughtPrice * quantity).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR'
          });
          return (
            <div className="mb-2" key={index}>
              <Card className="position">
                <CardContent>
                  <div className="row">
                    <div className="col-8 col-sm-8 col-md-4">
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className="cardHeader"
                      >
                        <div className="header">
                          <div>
                            <b>{name}</b>
                          </div>
                          <WSPrice security_id={security_id} />
                        </div>
                      </Typography>
                    </div>
                    <div className="col-4 col-sm-4 col-md-2">
                      <div className="quantityRow">
                        <div className="row">
                          <div className="col-md-12 col-sm-6">
                            <small className="p-2">Qty. {quantity}</small>
                          </div>
                          <div className="col-md-12 col-sm-6">
                            <small className="p-2">
                              Avg. {Number(boughtPrice).toFixed(2)}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 border-top">
                      <div className="table-responsive">
                        <table className="table table-condensed">
                          <tbody>
                            <tr>
                              <td width={'50%'}>
                                <small>Invested</small>
                                <br />
                                {invested}
                              </td>
                              <td className="currentAmt" width={'50%'}>
                                <small>
                                  <b className="text-success">Current</b>
                                </small>
                                <br />
                                <WSPrice
                                  security_id={security_id}
                                  quantity={quantity}
                                  boughtPrice={boughtPrice}
                                  template={PriceTemplates.POSITION}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="actions">
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() => handleAdd(security_id)}
                        >
                          Add
                        </button>{' '}
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleExit(id)}
                        >
                          Exit
                        </button>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          );
        })}
      </div>
    </StyledPositions>
  );
};
