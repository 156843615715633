import styled from 'styled-components';

export const WatchListWrapper = styled.div`
  .indices {
    text-transform: uppercase;
    font-size: small;
    font-weight: bold;
    border-bottom: 1px solid lightblue;
    display: block;
    color: darkblue;
  }
  .watchListTable {
    td {
      font-size: 0.8em;
      position: relative;
      .watchListActions {
        display: none;
        padding: 2px;
        button {
          margin: 0px 2px;
          width: 40px;
          border-color: #999;
        }
      }
    }

    td:hover {
      position: relative;
      .watchListActions {
        display: block;
        position: absolute;
        right: 0px;
        top: 5px;
      }
    }
  }
  .topIndices {
    .priceWrapper {
      display: inline-grid;
    }
  }
`;
